<template>

  <div class="row" ref="settingsForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="name"
              rules="required"
              name="The name"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Name *"
                        name="name"
                        fou
                        v-model="formData.name">
              </fg-input>
            </ValidationProvider>

            <fg-text type="text"
                     label="Description"
                     name="description"
                     fou
                     :rows="5"
                     v-model="formData.description">
            </fg-text>

            <ValidationProvider
              vid="ident"
              rules="required"
              name="The ident"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :disabled="editMode === true"
                        :error="failed ? errors[0]: null"
                        label="Ident *"
                        name="ident"
                        fou
                        v-model="formData.ident">
              </fg-input>
            </ValidationProvider>

            <fg-text  type="text"
                     label="Value"
                     name="value"
                     fou
                     :rows="5"
                     v-model="formData.value">
            </fg-text>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/settings/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select} from 'element-ui';

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        name: "",
        description: "",
        ident: "",
        value: "",
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.settingsForm
    });

    this.id = this.$route.params['id'];

    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Setting";
      this.getSetting();
    } else {
      this.editMode = false;
      this.formTitle = "Add Setting";
      this.loader.hide();
    }
  },


  methods: {

    getSetting() {
      let data = {
          'id' : this.id,
      };
      this.axios.post("settings/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Setting Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("settings/update/" + this.id, this.formData);
        successMessage = "Settings Item Updated Successfully";
      } else {
        request = this.axios.post("settings/create", this.formData);
        successMessage = "Settings Item Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/settings/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
