<template>

  <div class="row" ref="mobileSettingsForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h2>Android</h2>
              <ValidationProvider
                vid="android_description"
                rules=""
                name="The Android Description"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         label="Description"
                         name="description"
                         fou
                         :rows="5"
                         v-model="formData.android_description">
                </fg-text>
              </ValidationProvider>
                <ValidationProvider
                  vid="android_version"
                  rules="required"
                  name="The Android Version"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :disabled="editMode === true"
                            :error="failed ? errors[0]: null"
                            label="Android Version *"
                            name="android_version"
                            fou
                            v-model="formData.android_version">
                  </fg-input>
                </ValidationProvider>
                <div class="form-group pt-4">
                  <label>Lock For Update</label>&nbsp;
                  <l-switch v-model="formData.android_lock">
                    <i slot="on" class="fa fa-check"></i>
                    <i slot="off" class="fa fa-times"></i>
                  </l-switch>
                </div>
              </div>
              <div class="col-md-6">
                <h2>IOS</h2>
              <ValidationProvider
                vid="ios_description"
                rules=""
                name="The IOS Description"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         label="Description"
                         name="description"
                         fou
                         :rows="5"
                         v-model="formData.ios_description">
                </fg-text>
              </ValidationProvider>
                <ValidationProvider
                  vid="ios_version"
                  rules="required"
                  name="The IOS Version"
                  v-slot="{ passed, failed,errors }">
                  <fg-input type="text"
                            :disabled="editMode === true"
                            :error="failed ? errors[0]: null"
                            label="IOS Version *"
                            name="ios_version"
                            fou
                            v-model="formData.ios_version">
                  </fg-input>
                </ValidationProvider>
                <div class="form-group pt-4">
                  <label>Lock For Update</label>&nbsp;
                  <l-switch v-model="formData.ios_lock">
                    <i slot="on" class="fa fa-check"></i>
                    <i slot="off" class="fa fa-times"></i>
                  </l-switch>
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button v-if="$store.getters['auth/haveOneOfPermissions'](['mobile-settings/save'])"
              :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>&nbsp;

            <l-button @click="$router.push('/overview')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select} from 'element-ui';
import {Switch as LSwitch} from "@/components";

extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    LSwitch,
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        android_lock: false,
        android_version: "",
        android_description: "",
        ios_lock: false,
        ios_version: "",
        ios_description: "",
      },
    };
  },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.settingsForm
    });
    this.getMobileSetting();
  },


  methods: {

    getMobileSetting() {
      this.axios.post("mobile-settings/get").then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        console.log(error);
      })
    },


    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      let formData = this.formData;

      request = this.axios.post("mobile-settings/save", formData);
      successMessage = "Mobile Settings Item Updated Successfully";

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/overview");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {

        }
      }).finally(() => {
        this.submitting = false;
      });
    }

  }
}
</script>

<style>
</style>
