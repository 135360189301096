var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mobileSettingsForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v("Android")]),_c('ValidationProvider',{attrs:{"vid":"android_description","rules":"","name":"The Android Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","label":"Description","name":"description","fou":"","rows":5},model:{value:(_vm.formData.android_description),callback:function ($$v) {_vm.$set(_vm.formData, "android_description", $$v)},expression:"formData.android_description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"android_version","rules":"required","name":"The Android Version"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","disabled":_vm.editMode === true,"error":failed ? errors[0]: null,"label":"Android Version *","name":"android_version","fou":""},model:{value:(_vm.formData.android_version),callback:function ($$v) {_vm.$set(_vm.formData, "android_version", $$v)},expression:"formData.android_version"}})]}}],null,true)}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Lock For Update")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.android_lock),callback:function ($$v) {_vm.$set(_vm.formData, "android_lock", $$v)},expression:"formData.android_lock"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1),_c('div',{staticClass:"col-md-6"},[_c('h2',[_vm._v("IOS")]),_c('ValidationProvider',{attrs:{"vid":"ios_description","rules":"","name":"The IOS Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-text',{attrs:{"type":"text","label":"Description","name":"description","fou":"","rows":5},model:{value:(_vm.formData.ios_description),callback:function ($$v) {_vm.$set(_vm.formData, "ios_description", $$v)},expression:"formData.ios_description"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"ios_version","rules":"required","name":"The IOS Version"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","disabled":_vm.editMode === true,"error":failed ? errors[0]: null,"label":"IOS Version *","name":"ios_version","fou":""},model:{value:(_vm.formData.ios_version),callback:function ($$v) {_vm.$set(_vm.formData, "ios_version", $$v)},expression:"formData.ios_version"}})]}}],null,true)}),_c('div',{staticClass:"form-group pt-4"},[_c('label',[_vm._v("Lock For Update")]),_vm._v("  "),_c('l-switch',{model:{value:(_vm.formData.ios_lock),callback:function ($$v) {_vm.$set(_vm.formData, "ios_lock", $$v)},expression:"formData.ios_lock"}},[_c('i',{staticClass:"fa fa-check",attrs:{"slot":"on"},slot:"on"}),_c('i',{staticClass:"fa fa-times",attrs:{"slot":"off"},slot:"off"})])],1)],1)])]),_c('div',{staticClass:"card-footer text-right"},[(_vm.$store.getters['auth/haveOneOfPermissions'](['mobile-settings/save']))?_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]):_vm._e(),_vm._v("  "),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/overview')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }